import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="page-header">
    <div className="container">
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
        className="main-logo"
        title={siteTitle}
      >
        {siteTitle}
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
